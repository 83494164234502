<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-8">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">Setor</h3>
          <div class="row">
            <div class="col-md-9  mt-4">
              <!-- <label class="col-md-12">Eventos:*</label> -->
              <treeselect
               disabled
                :multiple="false"
                :options="lista_eventos"
                placeholder="Selecione o evento..."
                v-model="eventos_id"
                @input="preenxerCampos()"
              />
            </div>
     
          </div>
        </div>
        <div class="card-body table-responsive">
          <b-table
              :fields="['nome', 'acoes']"
              :items="lista_setor"
              :per-page="perPage"
              :current-page="currentPage"
              id="setor-table"
              class="table table-head-custom  table-vertical-center table-head-bg table-borderless"
              show-empty
              empty-text="Nenhum registro encontrado!"
          >

                 <template #head(acoes)><span></span></template>
                    <template #cell(acoes)="{item}">
              <div class="text-right w-100"> 
          
                <button
                    v-show="lista_permissoes_filial.u_Setor"
                    @click="atualizar(item)"
                          class="btn btn-icon btn-light btn-sm mx-1"
                    v-b-tooltip.hover title="Editar registro"
                >
                  <i class="far fa-edit text-primary"></i>
                </button>

                <button
                    v-show="lista_permissoes_filial.lock_Setor"
                    @click="confirm(item)"
                          class="btn btn-icon btn-light btn-sm mx-1"
                    v-b-tooltip.hover title="Bloquear/Desbloquear registro"
                >
                  <i class="fas fa-trash text-danger"></i>
                </button>
                           </div>
            </template>
          </b-table>
          <b-pagination
              v-model="currentPage"
              :total-rows="lista_setor.length"
              :per-page="perPage"
              aria-controls="setor-table"
          >
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/store/breadcrumbs.module";
import EventoService from "@/core/services/evento.service"

export default {
  data() {
    return {
         currentPage: 1,
      perPage: 10,
      eventos_id:  EventoService.getEventoId()
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Setor"}]);
  },
  created() {
    this.listar_setor();
  },
  computed: {
    lista_eventos() {
      return  EventoService.getEventos().map((cat) => ({
        id: cat.id,
        label: cat.id + " - " + cat.nome_evento+" - " + (cat.status == 1? 'ativo': "inativo"),
      }));
    },
    lista_setor() {
      return this.$store.state.setor.lista_setor.filter((item) => {
        if (!this.eventos_id) return true;
        return (
          String(item["eventos_id"])
            .toLowerCase()
            .indexOf(this.eventos_id + "".toLowerCase()) !== -1
        );
      });;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    mensagem_alert() {
      return this.$store.state.setor.mensagem_alert;
    },
  },
  methods: {
    async listar_setor() {
      await this.$store.dispatch("setor/listar_setor").finally(() => {
        this.$store.dispatch("configEmpresa/atualizar", "");
      });
    },
    atualizar(value) {
      this.$router.push({name: "createSetor"});
      this.$store.dispatch("configEmpresa/atualizar", value);
    },
    async confirm(value) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação bloqueara este Setor no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            this.bloquear(value);
          }
        },
      });
    },
    async bloquear(value) {
      await this.$store.dispatch("setor/bloquear_setor", value);
      this.alertMessage(this.mensagem_alert.tipo, this.mensagem_alert.message);
    },
    preenxerCampos(){

EventoService.setEventoId(this.eventos_id)

this.alertMessage('success', 'Selecionado Evento Global');
},

    alertMessage(tipo, mensagem) {
      this.$fire({
        title: "Aviso",
        text: mensagem,
        type: tipo,
        timer: 3000,
      }).then((r) => {
        console.log(r.value);
      });
    },

   
   
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>